import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./EgateL.css";
import logoCompressed from "./EGTLogoCompressed.jpg";
import { BsArrowReturnLeft } from "react-icons/bs";
import Footer2 from "../components/UG/FooterMain"
// const Footer2 = lazy(() => import("../components/UG/FooterMain"));
export const Egate = () => {
    const [showPopup, setShowPopup] = useState(false);
   
    // Function to handle the click event for "Coming Soon"
    const handleComingSoonClick = (e) => {
      e.preventDefault(); // Prevent redirection
      setShowPopup(true); // Show the popup
    };
    const closePopup = () => {
      setShowPopup(false);
    };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Ug_Home_page_main_container">
      <div className="Ug_Home_page_Sub_container">
        <div className="Ug_Home_page_content_container">
          <div className="landingEgate_page">
            <div className="Egate_logo_with_Link"></div>
            <section className="EGATE_eLanding_Container">
              <div className="Egate_landing">
                <div className="EGATE_landingE">
                  <div className="Egate_landiing_ojnkl">
                    <h2>Welcome to eGRADTutor...!</h2>
                    <a href="/About_us" class="about-us-button">
                      About us
                    </a>
                  </div>
                  <div className="Egate_loge_landing">
                    <img
                      src={logoCompressed}
                      loading="lazy"
                      alt="Egate Logo"
                      width="350"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="Egate_eLanding_Container Egate_landing">
              <div className="EGATE_eCordsLanding">
                {["UG", "PG"].map((type) => (
                  <div className="Ug_egatePAge-pg" key={type}>
                    <ul className="EGATE_elandUl">
                      <Link
                        className="EGATE_elanLink"
                        to={`/${type}Home`}
                        onClick={(e) => {
                          if (type === 'PG') {
                            handleComingSoonClick(e);
                          } else {
                            window.scrollTo(0, 0);
                          }
                        }}
                        // onClick={() => handleClickOnButtons()}
                      >
                        <h3 className="Pg_buttonn">
                          <span className="text">{type} ENTRANCE EXAMS</span>
                        </h3>
                      </Link>
                      {type === "UG" ? (
                        <>
                          {/* <li><Link
                                                        //  to="/UgExamHomePage/01"
                                                        // onClick={() => handleClickOnButtons()}
                                                    >IIT JEE (MAIN & ADVANCED)</Link></li> */}
                          <li>IIT JEE (MAIN & ADVANCED)</li>
                          {/* <li><Link 
                                                    to="/UgExamHomePage/09"
                                                    // onClick={() => handleClickOnButtons()}
                                                     >JEE (Advanced)</Link></li> */}
                          {/* <li><Link
                                                    //  to="/UgExamHomePage/02"
                                                    // onClick={() => handleClickOnButtons()}
                                                      >NEET (UG) <br/><p>(PHYSICS & CHEMISTRY)</p></Link></li> */}
                          <li>NEET (PHYSICS & CHEMISTRY)</li>
                          {/* <li><Link
                                                    //  to="/UgExamHomePage/03" 
                                                    // onClick={() => handleClickOnButtons()}
                                                     >BITSAT & VITEEE</Link></li> */}
                          <li>BITSAT & VITEEE</li>
                          {/* <li><Link 
                                                    // to="/UgExamHomePage/09"
                                                    // onClick={() => handleClickOnButtons()}
                                                     >TS & AP - EAMCET/EAPCET </Link></li> */}
                          <li>TS & AP - EAMCET/EAPCET</li>
                          <div className="UG_Exams_button_container">
                            <Link  to={`/${type}Home`}
                        onClick={() => window.scrollTo(0, 0)} className="Ug_Exams_button">
                              Enter <BsArrowReturnLeft />
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <li>GATE</li>
                          <li>IIT-JAM</li>
                          <li>ESE(PRELIMS)</li>
                          <li>PSU'S | ISRO | BARC</li>

                          <div className="UG_Exams_button_container">
                            <Link  to={`/${type}Home`}
                        onClick={(e) => handleComingSoonClick(e)} className="Ug_Exams_button">
                              Enter <BsArrowReturnLeft />
                            </Link>
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                ))}
                     
                {/* {showPopup && popupContent} */}
                {/* {showMaintainDiv &&(
                                  <div className="popup-overlay">
                                  <div className="popup-content">
                                    <p>We are under maintenance. Please try again later.</p>
                                    <button onClick={closeMaintainDiv}>Close</button>
                                  </div>
                                </div>
                                )} */}
              </div>
            </section>
           
              <Footer2 />
            
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup-overlayPgcourses">
          <div className="popupPgcourses">
            <h2>Coming Soon</h2>
            <p>This course will be available soon. Stay tuned!</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};
