import React from "react";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io5";
import { MdCall } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import "./StaticFooterCSS.css";
import { RiRegisteredLine } from "react-icons/ri";
const Footer2 = () => {
  return (
    <div className="footermaindiv">
      <div className="footerdiv">
        <div className="footeregraddiv">
          <h4 className="footerTitleColor2">eGRADTutor<sup><RiRegisteredLine /></sup></h4>
          {/* <p className="">
            eGRADTutor started with a team of graduates from IIT/IISc.We are
            experts in training students for UG, PG, MBA and CA entrance
            examinations.
          </p> */}
          <div className="footerlinksdiv footerterms ">
              <a href="/terms">Terms and Conditions</a> 
              <a href="/policy">Privacy Policy</a>
            <a href="/CancellationRefundPolicy">Pricing & Refund Policy</a>
            {/* <a href="#">Frequently Asked Questions</a> */}
          </div>
        </div>
        <div className="footerlinksdiv footercontact">
            <div className="footerlinksdiv1">
              <MdCall />
              <a href="tel:+917993270532" >+91-7993270532</a>
            </div>
            <div className="footerlinksdiv1">
              <IoIosMail />
              <a href="mailto:contact@egradtutor.in">contact@egradtutor.in</a>
            </div>
          </div>

        <div className="footeregraddiv  footeregradcontactus">
          <h4 className="footerTitleColor2">Contact Us</h4>
          <div className="">
            <p className="footerTitleCorporate">Corporate Office:</p>
            <div className="addressdiv">
              <p>
              eGRADTutor(eGATETutor Academy)
              </p>
              <p>R.K Nivas,2<sup>nd</sup> Floor,Shivam Road,</p>
              <p>New Nallakunta, Hyderabad - 500044.</p>
            </div>
          {/* <p className="address-line">
      ,
    </p> */}
          </div>
        
         
        </div>
        
      </div>
 
      <div className="footerCopyright">
      <div className="footeregradfollowus">
          <div className="footericonsdiv">
            <a>
              <IoLogoFacebook />
            </a>
            <a  href='https://www.instagram.com/egradtutor/'>
              <FaInstagram />
            </a>
            <a>
              <FaLinkedin />
            </a>
            <a href="https://www.youtube.com/@eGRADTutor">
              <FaYoutube />
            </a>
          </div>
        </div>
         <p>Copyright © 2024 eGRADTutor All rights reserved</p>
      </div>
    </div>
  );
};
 
export default Footer2;